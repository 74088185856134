.breadcrumb {
	.gb-inside-container {
		> span {
			> span {
				a {
					color: var(--paletteColor2);
					font-weight: bold;
					font-size: 13px;
					&:hover {
						color: rgba(36, 69, 39, 0.8);
					}
				}

				.breadcrumb_last {
					font-weight: bold;
					font-size: 13px;
				}
			}
		}
	}
}
