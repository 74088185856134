.wp-block-cover{
    margin-bottom: 0!important;
}

.bottle-size{
    img{
        height: 250px;
        width: auto;
    }
}

figure{
    box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.05), 0 12px 16px -4px rgba(16, 24, 40, 0.1);
}

.wp-block-gallery{
    box-shadow: none;
    figure{
        box-shadow: none;
    }
}

.no-shadow{
    box-shadow: none!important;
    >figure{
        box-shadow: none!important;
    }
}

.category-arrangementen{
    #main{
        .ct-container{
            max-width: var(--default-editor, var(--block-wide-max-width));
            width: var(--default-editor, var(--block-width));
            margin-left: var(--default-editor, auto);
            margin-right: var(--default-editor, auto);
            --block-width: var(--has-wide, var(--container-width));
            --block-max-width: var(--has-wide, var(--container-max-width));
            --block-wide-max-width: var(--has-wide, calc(var(--container-max-width) + var(--wide-offset) * 2));
            --boxed-content-max-width: var(--container-max-width);
            >section{
                max-width: 1200px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}

#main{
    overflow: hidden;
}