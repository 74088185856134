.home-header-slider{
    height: 640px;
    position: relative;
    margin-bottom: 0!important;
    display: none;
    &.loaded{
        display:block ;
    }
    @include break-below(sm){
        height: 348px;
    } 
    .one-slide{
        height: 640px;
        @include break-below(sm){
            height: 348px;
        }
    }

    .next-arrow, .prev-arrow{
        position: absolute;
        left: 1px;
        bottom: 1px;
        z-index: 1;
        background: #fff;
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover{
            cursor: pointer;
        }
    }

    .next-arrow{
        left: 66px;
    }
}

.box-shadow-for-cards{
    box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.05), 0 12px 16px -4px rgba(16, 24, 40, 0.1);
}