.gradient-section {
	position: relative;
	&::after{
		content: "";
		display: block;
		background: var(--paletteColor7);
		width: 300%;
		position: absolute;
		height: 100%;
		z-index: -1;
		top: 0;
	}
	@media screen and (max-width: 1025px) {
		&::after{
			left: -500px;
		}
	}
	// @media screen and (max-width: 768px) {
	// 	&::after{
	// 		height: 1180px;
	// 	}
	// }
}

.element-for-link {
	> a {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		color: transparent;
		&:hover {
			color: transparent;
		}
	}
}

.zoom-in{
	transform: scale(1);
	transition: 1s all;
	&:hover{
		transform: scale(1.05);
	}
}

.without-relative{
	>.gb-inside-container{
		position: static!important;
	}
}